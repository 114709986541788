var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border card-shadow",staticStyle:{"max-width":"400px"}},[_c('v-layout',{staticClass:"px-4 py-2 border-bottom"},[_c('div',{staticClass:"my-auto font-level-3-bold fw-600"},[_vm._v("Project Status")]),_c('v-spacer')],1),_c('div',{staticClass:"px-4 py-4 w-100 mb-4 border-bottom d-flex justify-content-between align-items-center"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":"360","size":"70","width":"08","value":"65","color":"green"}},[_vm._v(" 85% ")])],1)]),_c('div',{staticClass:"px-4 py-4 w-100 mb-4 border-bottom d-flex justify-content-between align-items-center"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":"360","size":"70","width":"08","value":"65","color":"green"}},[_vm._v(" 85% ")])],1)]),_c('div',{staticClass:"px-4 py-4 w-100 mb-4 border-bottom d-flex justify-content-between align-items-center"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":"360","size":"70","width":"08","value":"65","color":"green"}},[_vm._v(" 85% ")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"my-auto font-level-3-bold primary--text mr-2"},[_c('span',{staticClass:"fw-600"},[_vm._v("Asset Utilization ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"fw-600",staticStyle:{"font-size":"30px"}},[_vm._v("24.2 %")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"my-auto font-level-3-bold primary--text mr-2"},[_c('span',{staticClass:"fw-600"},[_vm._v("Asset Utilization ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"fw-600",staticStyle:{"font-size":"30px"}},[_vm._v("24.2 %")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"my-auto font-level-3-bold primary--text mr-2"},[_c('span',{staticClass:"fw-600"},[_vm._v("Asset Utilization ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"fw-600",staticStyle:{"font-size":"30px"}},[_vm._v("24.2 %")])])
}]

export { render, staticRenderFns }