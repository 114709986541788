<template>
	<div class="border card-shadow" style="max-width: 400px">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Project Status</div>
			<v-spacer></v-spacer>
		</v-layout>
		<div class="px-4 py-4 w-100 mb-4 border-bottom d-flex justify-content-between align-items-center">
			<p class="my-auto font-level-3-bold primary--text mr-2">
				<span class="fw-600">Asset Utilization </span>
			</p>
			<div class="mt-2">
				<span class="fw-600" style="font-size: 30px">24.2 %</span>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" value="65" color="green">
					85%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-4 w-100 mb-4 border-bottom d-flex justify-content-between align-items-center">
			<p class="my-auto font-level-3-bold primary--text mr-2">
				<span class="fw-600">Asset Utilization </span>
			</p>
			<div class="mt-2">
				<span class="fw-600" style="font-size: 30px">24.2 %</span>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" value="65" color="green">
					85%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-4 w-100 mb-4 border-bottom d-flex justify-content-between align-items-center">
			<p class="my-auto font-level-3-bold primary--text mr-2">
				<span class="fw-600">Asset Utilization </span>
			</p>
			<div class="mt-2">
				<span class="fw-600" style="font-size: 30px">24.2 %</span>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" value="65" color="green">
					85%
				</v-progress-circular>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "OverAllStats",
};
</script>
